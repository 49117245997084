// AUTH
import Login from '@auths/Login';
import VerifyPage from '@auths/VerifyPage';
import Register from '@auths/Register';
import ForgetPassword from '@auths/ForgetPassword';
import ResetPassword from '@auths/ResetPassword';
import SplashScreen from '@auths/SplashScreen';

// GUEST
import Maintenance from '@pages/Maintenance';
import ComingSoon from '@pages/ComingSoon';

// LINK ACC TO MSTORE
import LinkAccToMstore from '@pages/LinkAcc/LinkAccToMstore';

// ACCOUNT
import Dashboard from '@pages/Dashboard';
import Profile from '@pages/Profile';
import Account from '@pages/Account';
// import Profile from '@pages/Account/BasicInfo';
import Address from '@pages/Account/Address';
import AddressDetails from '@pages/Account/AddressDetails';
import PasswordHome from '@pages/Account/PasswordHome';
import Password from '@pages/Account/Password';
import SecurityPassword from '@pages/Account/SecurityPassword';
import TwoFactorAuth from '@pages/Account/TwoFactorAuth';
import CryptoAddress from '@pages/Account/CryptoAddress';
import Recruit from '@pages/Account/Recruit';
import ReferralQr from '@pages/Account/ReferralQr';
import Genealogy from '@pages/Genealogy/Genealogy';

// WALLET
import Wallet from '@pages/Wallet/Wallet';
import Transaction from '@pages/Wallet/Transaction';
import Transfer from '@pages/Wallet/Transfer';
import Deposit from '@pages/Deposit';
import Withdraw from '@pages/Withdraw';
// import TradeMerchantWithdraw from '@pages/Withdraw/TradeMerchantWithdraw';

// BONUS 
import Bonus from '@pages/Wallet/Bonus';

// PLAN
import Plans from '@pages/Plan';
import Payment from '@pages/Plan/Payment';
import PlanHistory from '@pages/Plan/PlanHistory';

//NOTICE
import Notice from '@pages/Notice';
import NoticeDetail from '@pages//Notice/Detail';

//TRADE
import Trade from '@pages/Trade';
import TradeSellingForm from '@pages/Trade/TradeSellingForm';
import Spend from '@pages/Spend';
import SpendHistory from '@pages/Spend/History';
import TradeTransfer from '@pages/Trade/Transfer/Transfer';
import SpecialTransfer from '@pages/Trade/Transfer/SpecialTransfer';
import RedeemList from '@pages/Trade/RedeemList';
import RedeemTransaction from '@pages/Trade/RedeemTransaction';
import TradeSellTransactionHistory from '@pages/Trade/TradeSellTransactionHistory';
import ConvertAPtoValue from '@pages/Trade/ConvertAPtoValue';

import BonusTokenHistory from '@pages/Trade/TradeTransactionHistory/BonusTokenHistory';
import BuyTradeHistory from '../pages/Trade/BuyTradeHistory';
import RedeemCodeHistory from '@pages/Plan/RedeemCodeHistory';

// MarketPrice
// import MarketPrice from '@pages/Trade/MarketPrice';
// import MarketPriceDetails from '@pages/Trade/MarketPrice/details';

// Merchant 
// import BecomeMerchant from '@pages/Merchant/BecomeMerchant';
import MerchantListing from '../pages/Merchant/MerchantListing';
// import MerchantConvert from '@pages/Merchant/Convert';
// import MerchantTransfer from '@pages/Merchant/Transfer';
// import ThirdPartyConvert from '@pages/Merchant/ThirdPartyConvert';
// import ThirdPartyConvertForm from '@pages/Merchant/ThirdPartyConvertForm';
// import WithdrawalsHistory from '@pages/Merchant/WithdrawalsHistory';
// import MerchantTradeSpendingHistory from '@pages/Merchant/MerchantTradeSpendingHistory';
import MerchantWallet from '@pages/Merchant/Wallet';

// HELP CENTER
import HelpCenter from '@pages/HelpCenter';

// THIRD PARTY
import ThirdPartyCheckout from '@pages/ThirdParty/Checkout';

// ONLY CAN ACCESS AFTER LOGIN
export const private_routes = [
    // Link to Mstore
   /*  {
        path: "/linkAcc/toMstore",
        component: <LinkAccToMstore />,
        title:'title.linkToMstore',
    }, */
    // ------ //
    {
        path: "/",
        component: <Dashboard />,
        title:'title.dashboard',
    },
    {
        path: "/profile",
        component: <Profile />,
        title:'title.profile',
    },
    // ACCOUNT
    {
        path: "/account",
        component: <Account />,
        title:'title.account'
    },
    // {
    //     path: "/account/profile",
    //     component: <Profile />,
    //     title:'title.profile',
    // },
    // {
    //     path: "/account/address",
    //     component: <Address />,
    //     title:'title.address',
    // },
    // {
    //     path: "/account/address/details/:id",
    //     component: <AddressDetails />,
    //     title:'title.editAddress',
    // },
    // {
    //     path: "/account/address/details",
    //     component: <AddressDetails />,
    //     title:'title.addAddress',
    // },
    {
        path: "/account/passwordHome",
        component: <PasswordHome />,
        title:'title.password',
    },
    {
        path: "/account/password",
        component: <Password />,
        title:'title.password',
    },
    {
        path: "/account/2fa",
        component: <TwoFactorAuth />,
        title:'title.twoFactorAuth',
    },
    {
        path: "/account/crypto-address",
        component: <CryptoAddress />,
        title:'title.cryptoAddress',
    },
    {
        path: "/account/recruit",
        component: <Recruit />,
        title:'title.register',
    },
    {
        path: "/account/referral-qr",
        component: <ReferralQr />,
        title:'title.referralQr',
    },
    //NOTICE
    {
        path: "/account/notices",
        component: <Notice />,
        title: 'title.newsAnnouncements',
    },
    {
        path: "/account/notice/:id",
        component: <NoticeDetail />,
        title: 'title.noticeDetail',
    },
    // GENEALOGY
    {
        path: "/community/:page",
        component: <Genealogy/>,
        title:'title.community',
    },
    // WALLET
    {
        path: "/funds/:page",
        component: <Wallet />,
        title:'title.wallet',
    },
    {
        path: "/transaction",
        component: <Transaction />,
        title:'title.transaction',
    },
    {
        path: "/convert",
        component: <Transfer />,
        title:'title.convert',
    },
    {
        path: "/transfer",
        component: <Transfer />,
        title:'title.transfer',
    },
    {
        path: "/deposit",
        component: <Deposit />,
        title:'title.deposit',
    },
    {
        path: "/withdraw",
        component: <Withdraw />,
        title:'title.withdraw',
    },
   /*  {
        path: "/merchant-withdraw",
        component: <TradeMerchantWithdraw />,
        title:'title.withdraw',
    }, */
    /* {
        path: "/merchant-withdraw-history",
        component: <WithdrawalsHistory />,
        title:'title.merchantWithdrawalsHistory',
    }, */
    /* {
        path: "/merchant-spending-history",
        component: <MerchantTradeSpendingHistory />,
        title:'title.merchantWithdrawalsHistory',
    }, */
    // BONUS
    {
        path: "/bonus",
        component: <Bonus />,
        title:'title.bonus',
    },
    // PLAN
    {
        path: "/plans",
        component: <Plans/>,
        title:'title.plan',
    },
    {
        path: "/payment/:id",
        component: <Payment/>,
        title:'title.plan',
    },
    {
        path: "/plan-histories",
        component: <PlanHistory/>,
        title:'title.planHistory',
    },
    {
        path: "/redeem-code-histories",
        component: <RedeemCodeHistory/>,
        title:'title.redeemCodeHistory',
    },
    // TRADE
    {
        path: "/trade",
        component: <Trade/>,
        title:'title.trade',
    },
    {
        path: "/trade/transfer",
        component: <TradeTransfer/>,
        title:'title.transfer',
    },
    {
        path: "/trade/special-transfer",
        component: <SpecialTransfer/>,
        title:'title.transfer',
    },
    {
        path: "/trade/sell",
        component: <TradeSellingForm/>,
        title:'title.tardeSell',
    },
    {
        path: "/trade/sell-transaction-history",
        component: <TradeSellTransactionHistory/>,
        title:'title.tardeSellHistory',
    },
    {
        path: "/trade/redeem-list",
        component: <RedeemList/>,
        title:'title.redeem',
    },
    {
        path: "/trade/redeem-transaction",
        component: <RedeemTransaction/>,
        title:'title.redeemTransaction',
    },
    {
        path: "/trade/bonus-token-histories",
        component: <BonusTokenHistory/>,
        title:'title.bonusTokenHistory',
    },
    {
        path: "/trade/buy-trade-histories",
        component: <BuyTradeHistory/>,
        title:'title.buyTradeHistory',
    },
    {
        path: "/merchant-wallet/:page",
        component: <MerchantWallet />,
        title:'title.wallet',
    },
    {
        path: "/merchant-transaction",
        component: <Transaction />,
        title:'title.transaction',
    },
    {
        path: "/merchant-withdraw-wallet",
        component: <Withdraw />,
        title:'title.withdraw',
    },
    /* {
        path: "/merchant-convert-ap-to-value",
        component: <ConvertAPtoValue/>,
        title:'title.convertAPtoValue',
    }, */
    // {
    //     path: "/coming-soon",
    //     component: <ComingSoon/>,
    //     title:'comingSoon.title',
    // },
    {
        path:"/spend",
        component: <Spend />,
        title:'title.spend',
    },
    {
        path:"/spend-history",
        component: <SpendHistory />,
        title:'title.spendHistory',
    }, 
    /* {
        path:"/market-price",
        component: <MarketPrice />,
        title:'title.marketPrice',
    },
    {
        path:"/market-price-details/:range",
        component: <MarketPriceDetails />,
        title:'title.marketPriceDetails',
    }, */
    // {
    //     path:"/become-merchant",
    //     component: <BecomeMerchant />,
    //     title:'title.becomeMerchant',
    // },
    {
        path:"/merchant-listing",
        component: <MerchantListing />,
        title:'title.merchantListing',
    },
    // {
    //     path:"/merchant-convert",
    //     component: <MerchantConvert />,
    //     title:'profile.convert',
    // },
    // {
    //     path:"/merchant-transfer",
    //     component: <MerchantTransfer />,
    //     title:'profile.transfer',
    // },
    // {
    //     path:"/to-thirdparty",
    //     component: <ThirdPartyConvert />,
    //     title:'title.toThirdParty',
    // },
    // {
    //     path:"/to-thirdparty/form",
    //     component: <ThirdPartyConvertForm />,
    //     title:'title.toThirdParty',
    // },
];

// CAN ACCESS BEFORE OR AFTER LOGIN
export const public_routes = [
    {
        path: "/maintenance",
        component: <Maintenance/>,
        exact: true
    },
    {
        path: "/login-redirect",
        component: <Login />,
        title: 'title.login',
    },
    {
        path: "/third-party-checkout/:key",
        component:<ThirdPartyCheckout />,
        title:'title.thirdPartyCheckout',
    }
];

// ONLY CAN ACCESS BEFORE LOGIN
export const public_restricted_routes = [
    {
        path: "/register",
        component: <Register />,
        title:'title.register',
    },
    {
        path: "/forgot-password",
        component: <ForgetPassword />,
        title:'forgetPassword.title',
    },
    {
        path: "/reset-password",
        component: <ResetPassword />,
        title:'forgetPassword.title',
    },
    {
        path: "/login",
        component: <Login />,
        title: 'title.login',
    },
    // {
    //     path: "/landing",
    //     component: <SplashScreen />,
    //     title:'title.splashScreen',
    // },
    {
        path: "/verifyPage/:id/:hash",
        component: <VerifyPage />,
        title: 'title.verifyPage',
    },
];