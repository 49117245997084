import _ from 'lodash';
import moment from 'moment';
import { TextField, Typography, InputAdornment, IconButton, Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, memo } from 'react';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useParams } from 'react-router-dom';
import { authFail, authSuccess } from '@actions';
import { getUrl, removeLoginAccess, postUrl } from '@utils/ApiAction';
import { tradingAmountFormat } from '@utils/Tools';

import TwoFA from '@components/TwoFA';

import { FiEye, FiEyeOff } from "react-icons/fi";

const INITIAL_STATE = { securityPassword: '', authenticationCode: '', username: '', password: '', type: 'web' };

const Checkout = memo(() => {
    const theme = useTheme();
    let { key } = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const { username } = useSelector(state => state.user);
    const { accessToken } = useSelector(state => state.general);
    const [state, setState] = useState(INITIAL_STATE);
    const [info, setInfo] = useState({});
    const [wallets, setWallets] = useState({});
    const [setting, setSetting] = useState({});
    const [option, setOption] = useState({
        securityPasswordShow: false,
        passwordShow: false,
        guest: false,
        loginDialog: false,
        paymentFailed: false,
    });
    const [twoFASetting, setTwoFASetting] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { addAlert, setLoading } = useNotificationLoading();
    const [disclaimerDialog, setDisclaimerDialog] = useState(false);

    const calculateTimeLeft = () => {
        const now = moment();
        const endDate = moment(info?.timeout_date);
        const duration = moment.duration(endDate.diff(now));

        const minutes = duration.minutes();
        const seconds = duration.seconds();

        return {
            minutes: minutes >= 0 ? minutes : 0,
            seconds: seconds >= 0 ? seconds : 0,
        };
    };
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const formatTime = (time) => String(time).padStart(2, '0');

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {

        if (info?.timeout_date) {
            const timer = setInterval(() => {
                const newTimeLeft = calculateTimeLeft();
                // Stop the timer when the countdown is finished
                if (newTimeLeft.minutes === 0 && newTimeLeft.seconds === 0) {
                    clearInterval(timer);
                    if (info?.ulid) {
                        expiredOrder();
                    }
                    setOption(prevState => ({ ...prevState, paymentFailed: true }));
                }
                setTimeLeft(newTimeLeft);
            }, 1000); // Update every minute

            // Clean up the interval on component unmount
            return () => clearInterval(timer);
        }
        // eslint-disable-next-line
    }, [info?.timeout_date]);

    useEffect(() => {
        setLoading(true);
        checkFunction();
        setLoading(false);
        // eslint-disable-next-line
    }, [username, info]);

    const checkFunction = () => {
        let infoUser = info?.user?.username;
        let checking = accessToken ? (infoUser && username && infoUser !== username) : true;
        if (checking) {
            setOption(prevState => ({ ...prevState, guest: true, loginDialog: true }));
            setState(prevData => ({ ...prevData, username: info?.user?.username }));
        }
    }

    useEffect(() => {
        setLoading(true);

        getUrl(`/member-payment-order/${key}`).then(response => {
            setLoading(false);
            if (response?.status) {
                setInfo(response?.data?.detail);
                setWallets(response?.data?.wallet_listing);
                setSetting({
                    walletIds: response?.data?.wallet_ids,
                    walletName: response?.data?.wallet_name,
                });

                if (response?.data?.detail?.status !== 10 && response?.data?.detail?.direct_url) {
                    window.location.replace(response?.data?.detail?.direct_url);
                }
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error?.message || t('error.contactSupport'), 'error', '');
        });

        if (accessToken) {
            setLoading(true);
            getUrl('get-setting-value').then(response => {
                setLoading(false)
                if (response.status) {
                    setTwoFASetting(response.twofa)
                }
            }).catch(error => {
                setLoading(false)
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            })
        }
        // eslint-disable-next-line
    }, [accessToken])

    const handlePasswordShow = (name) => {
        setOption(prevState => ({ ...prevState, [name]: !option[name] }));
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleDialog = (type, value) => {
        switch (type) {
            case 'login-box':
                setOption(prevState => ({ ...prevState, loginDialog: value }));
                break;
            default:
                break;
        }
    }

    const totalDisplay = (list) => {
        let total = 0;
        let decimal = 0;
        if (_.size(list) > 0) {
            _.map(list, (detail, detailKey) => {
                let wallet = _.find(wallets, { 'code': detailKey });
                if (wallet) {
                    if (wallet?.decimal > decimal) {
                        decimal = wallet?.decimal;
                    }
                }
                total = total + detail;
            });
        }

        return parseFloat(total).toFixed(decimal);
    }

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const insertSecurityPwd = () => {
        if (state.securityPassword) {
            setDrawerOpen(true);
        } else {
            addAlert('', t('error.requiredFields', { 'value': t(`user.securityPassword`) }), 'error', '');

            // Close the drawer
            setDrawerOpen(false);
        }
    };

    const agreeDisclaimer = () => {
        if (state?.username && state?.password) {
            setDisclaimerDialog(true);
        } else {
            addAlert('', t('login.loginError'), 'error', '');
        }
    }

    const updateDisclaimer = (id) => {
        postUrl(`/user-agree-disclaimer/${id}`).then(response => {
            if (response.status) {
                // do nothing
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const logout = () => {
        setDisclaimerDialog(false);
        setState(INITIAL_STATE);
        removeLoginAccess();
        setOption(prevState => ({ ...prevState, guest: true, loginDialog: true }));
    };

    const loginUser = (event) => {
        setLoading(true);
        event.preventDefault();
        let params = {
            username: state?.username,
            password: state?.password,
            type: state?.type,
            direct: false,
        }
        postUrl('/login', params).then(response => {
            setLoading(false);
            if (response.status) {
                setOption(prevState => ({ ...prevState, guest: false, loginDialog: false }));
                dispatch(authSuccess(response.data));
                updateDisclaimer(response.data.id);
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
            setDisclaimerDialog(false);
        });
    }

    const expiredOrder = () => {
        setLoading(true);
        let params = {
            order_id: info?.ulid,
        }

        postUrl('/expire-order', params).then(response => {
            setLoading(false);
            if (response.status) {
                window.location.replace(response?.url);
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false);
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    }

    const handleSubmit = () => {
        setLoading(true);

        let params = {
            order_id: info?.ulid,
            security_password: state?.securityPassword,
            authentication_code: state.authenticationCode,
        }

        postUrl('/make-payment', params).then(response => {
            setLoading(false);
            if (response.status) {
                window.location.replace(response?.url);
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            setLoading(false)
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    }

    return (
        <div className='flex-c-m' style={{ height: '100vh' }}>
            <div className='p-all-30 gradient-gold-border' style={{ width: width < 860 ? '80%' : '50%', backgroundColor: theme.palette.darkBase, borderRadius: 5 }}>
                <div className='flex-l-m'>
                    <div>
                        <img src="/images/logos/logo-with-title.png" style={{ height: 70, margin: 0 }} alt="logo" loading='lazy' />
                    </div>
                </div>
                {
                    _.size(info) > 0 &&
                    <div className='flex-c-m p-t-30 w-full' style={{ flexDirection: 'column' }}>
                        <div className='flex-sb-m w-full'>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} >{t('thirdParty.payTo')}</Typography>
                            <Typography style={{ wordBreak: 'break-all', textAlign: 'right', width: '50%' }}>{process.env.REACT_APP_APP_NAME}</Typography>
                        </div>
                        <div className='flex-sb-m w-full'>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} >{t('thirdParty.title')}</Typography>
                            <Typography style={{ wordBreak: 'break-all', textAlign: 'right', width: '50%' }}>{info?.title || "-"}</Typography>
                        </div>
                        <div className='flex-sb-m w-full'>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} >{t('thirdParty.orderId')}</Typography>
                            <Typography style={{ wordBreak: 'break-all', textAlign: 'right', width: '50%' }}>{info?.ulid || "-"}</Typography>
                        </div>
                        <div className='flex-sb-m w-full'>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{t('thirdParty.issueDate')}</Typography>
                            <Typography style={{ wordBreak: 'break-all', textAlign: 'right', width: '60%' }}>{info?.created_at || "-"}</Typography>
                        </div>
                        {
                            _.size(info?.amount) > 0 ?
                                <div className='w-full'>
                                    {
                                        _.map(info?.amount, (detail, detailKey) => {
                                            if (_.includes(['TRADEVALUE'], detailKey)) {
                                                //estimate unit
                                                let unit = info?.rate > 0 ? Math.ceil(_.clone(detail) / info?.rate) : 0;
                                                return (
                                                    <div className='flex-sb-m w-full' key={detailKey}>
                                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} >{t('trade.title')}</Typography>
                                                        <div className='flex-r-m' style={{ wordBreak: 'break-all', textAlign: 'right', width: '60%' }}>
                                                            <Typography variant="body2" style={{ color: '#949494', fontStyle: 'italic', textTransform: 'uppercase' }}>{` ≈ ${tradingAmountFormat(parseFloat(unit).toFixed(0))} ${t('general.unit')}`}</Typography>
                                                            <Typography variant="body2" style={{ marginLeft: 10, color: theme.palette.darkBase.main }}>{`$${tradingAmountFormat(parseFloat(detail).toFixed(3)) || 0}`}</Typography>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                let wallet = _.find(wallets, { 'code': detailKey });
                                                if (wallet) {
                                                    if (_.size(setting?.walletIds?.wallet_ids) > 0 && _.includes(setting?.walletIds?.wallet_ids, parseInt(wallet?.id))) {
                                                        return (
                                                            <div className='flex-sb-m w-full' key={detailKey}>
                                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }} >{setting?.walletName[i18n.language] ? setting?.walletName[i18n.language] : setting?.walletName?.en}</Typography>
                                                                <Typography variant="body2" style={{ wordBreak: 'break-all', textAlign: 'right', width: '60%' }}>{parseFloat(detail).toFixed(wallet?.decimal)}</Typography>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className='flex-sb-m w-full' key={detailKey}>
                                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}  >{wallet?.name[i18n.language] ? wallet?.name[i18n.language] : wallet?.name?.en}</Typography>
                                                                <Typography variant="body2" style={{ }}>{parseFloat(detail).toFixed(wallet?.decimal)}</Typography>
                                                            </div>
                                                        )
                                                    }
                                                } else {
                                                    return null;
                                                }
                                            }
                                        })
                                    }
                                </div>
                                : null
                        }

                        {
                            option?.paymentFailed ?
                                <div className="p-all-10">
                                    <Typography variant='h6' style={{ color: theme.palette.primary.main }}>{t('error.paymentFailed')}</Typography>
                                </div>
                                :
                                <>
                                    <div className='flex-c-m w-full p-t-40'>
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            name='securityPassword'
                                            placeholder={t('title.securityPassword')}
                                            type={option?.securityPasswordShow ? 'text' : 'password'}
                                            required
                                            error={inputErrors && inputErrors.security_password ? true : false}
                                            InputLabelProps={{ shrink: true }}
                                            value={state?.securityPassword || ''}
                                            onChange={handleChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => handlePasswordShow('securityPasswordShow')}>
                                                            {option?.['securityPasswordShow'] ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                disableUnderline: true
                                            }}
                                        />
                                    </div>
                                    {
                                        inputErrors && inputErrors?.security_password ?
                                            <div className='flex-sb m-t-10 w-full' style={{ flexDirection : width < 1320 ? 'column':'row' , alignItems: width < 1320 ? 'flex-start': 'center'}}>
                                                    <div style={{ width: width < 1320 ? '100%' : '80%' }}>
                                                    <Typography color='error' variant='subtitle2'>
                                                        {inputErrors && inputErrors?.security_password ? (_.map(inputErrors?.security_password).join(' ')) : ''}
                                                    </Typography>
                                                </div>
                                                <div className='flex-c' style={{ width: width < 1320 ? '100%' : '20%' , justifyContent: width < 1320 ? 'flex-start': 'flex-end' }}>
                                                    <Typography variant="body2" style={{ }}>
                                                        {`${t('thirdParty.timeLeft')} :`}
                                                    </Typography>
                                                    <Typography className='p-l-5' variant="body2" style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                                                        {`${formatTime(timeLeft.minutes)} : ${formatTime(timeLeft.seconds)}` || "-"}
                                                    </Typography>
                                                </div>
                                            </div>
                                            :
                                            <div className='flex-r-m w-full p-t-10'>
                                                <div className='flex-c-m'>
                                                    <Typography variant="body2" style={{ color: theme.palette.darkBlack.main }}>
                                                        {`${t('thirdParty.timeLeft')} :`}
                                                    </Typography>
                                                    <Typography className='p-l-5' variant="body2" style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                                                        {`${formatTime(timeLeft.minutes)} : ${formatTime(timeLeft.seconds)}` || "-"}
                                                    </Typography>
                                                </div>
                                            </div>
                                    }
                                    <div className='flex-sb-m w-full p-t-40'>
                                        <TwoFA
                                            inputErrors={inputErrors?.authentication_code}
                                            open={drawerOpen}
                                            onClose={() => setDrawerOpen(false)}
                                            value={state?.authenticationCode || ''}
                                            handleChange={({ target }) => {
                                                const numericInput = target.value.replace(/\D/g, '');
                                                setState({ ...state, authenticationCode: numericInput });
                                            }}
                                            onClick={handleSubmit}
                                        />
                                    </div>
                                    {
                                        _.size(inputErrors) > 0 &&
                                        _.map(inputErrors, (error, errorKey) => {
                                            if (_.includes(['username'], errorKey)) {
                                                return (
                                                    <div className="p-all-3" key={errorKey}>
                                                        <Typography variant='subtitle1' style={{ color: theme.palette.primary.main }}>{`${error} `}</Typography>
                                                    </div>
                                                )
                                            }
                                        })

                                    }
                                    <div className='flex-c-m'>
                                        <Button variant='contained' className='big-button m-t-10'
                                            sx={{
                                                "&.Mui-disabled": {
                                                    backgroundColor: "#D9D9D9",
                                                    color: "#ADADAD",
                                                    boxShadow: '2px 2px 4px 0px #00000040 inset',
                                                },
                                            }}
                                            onClick={() => {
                                                window.location.replace(info?.redirect_url);
                                            }}
                                        >{t('button.cancel')}</Button>
                                        <Button variant="contained" onClick={twoFASetting ? insertSecurityPwd : handleSubmit} className='big-button m-t-10' style={{ marginLeft: 20 }} fullWidth>
                                            {t('button.submit')}
                                        </Button>
                                    </div>
                                </>
                        }
                    </div>
                }
            </div>
            <Dialog open={option?.loginDialog}>
                <DialogContent>
                    <div className='p-all-10'>
                        <Typography variant='h6' style={{ color: theme.palette.secondary.main }}>{t('title.login')}</Typography>
                        <TextField
                            variant="standard"
                            placeholder={t('user.username')}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="username"
                            autoComplete="off"
                            value={state.username || ""}
                            name="username"
                            onChange={handleChange}
                            className={classes.textFieldStyle}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            disabled
                        />
                        <TextField
                            variant="standard"
                            placeholder={t('user.password')}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handlePasswordShow('passwordShow')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {option.passwordShow ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            type={option.passwordShow ? 'text' : 'password'}
                            color='primary'
                            name="password"
                            value={state.password}
                            onChange={handleChange}
                            className={classes.textFieldStyle}
                            helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                            error={inputErrors && inputErrors.password ? true : false}
                        />
                        <Button variant="contained" fullWidth color="primary" onClick={agreeDisclaimer} className='big-button' style={{ marginTop: 20, background: theme.palette.primary.main }} >
                            {t('button.login')}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={disclaimerDialog} >
                <Typography variant="h6" color="primary" style={{ padding: '15px 20px', wordBreak: 'break-word' }}>{t('disclaimer.title')}</Typography>
                <DialogContent >
                    <Trans i18nKey={'disclaimer.info'} components={{ 1: <br /> }} />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        sx={{
                            "&.MuiButton-root": {
                                backgroundColor: theme.palette.darkBase.main,
                                color: theme.palette.white.main,
                            },
                        }}
                        onClick={logout}
                    >
                        {t('button.disagree')}
                    </Button>
                    <Button variant='contained' onClick={loginUser}>{t('button.agree')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
}));

export default Checkout;