import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { currencyFormat } from '@utils/Tools';
// import { storePaths } from '@utils/Tools';
import moment from 'moment';

import CloseIcon from '@mui/icons-material/Close';
import { FaCheck, FaArrowCircleDown } from "react-icons/fa";
import theme from '../Theme';
// import zIndex from '@mui/material/styles/zIndex';

const Receipt = props => {
    // const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const { type, data } = props;
    // let location = useLocation();
    // let navigate = useNavigate();
    const containerRef = useRef();
    let currDate = moment();

    const [isBottom, setIsBottom] = useState(false);

    useEffect(() => {
        const container = containerRef.current;

        const handleScroll = () => {
            const isAtBottom = container.scrollTop + container.clientHeight >= container.scrollHeight - 10;

            if (isAtBottom) {
                setIsBottom(true);
            } else {
                setIsBottom(false);
            }
        };

        // Attach the scroll event listener to the container
        container.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => { container.removeEventListener('scroll', handleScroll); };
    }, []);

    // const handleComplete = () => {
    //     navigate(completedLink, { state: { from: storePaths(location) } });
    // }

    // Determine whether have the service fee
    // const serviceFee = _.size(data?.details) > 0 ? parseFloat(data?.details?.['service_fee']) : 0;

    // Extract the decimal value from the wallet data, default to 2 if not available
    const decimalPlaces = data?.wallet_balance?.wallet?.decimal || 2;

    // Format the amount with the appropriate number of decimal places
    const formattedAmount = parseFloat(type === 'transaction' ? data?.amount : parseFloat(data?.price)).toFixed(decimalPlaces);

    // Determine the sign and color based on the transaction.factor
    const sign = data?.factor === 1 ? '+' : (data?.factor === -1 ? '-' : '');
    // const color = data?.factor === -1 ? '#C2872E' : '#3C946F';

    const handleClose = () => {
        // Call the handleClose callback passed from TransactionItem
        props.handleClose();
    };
    let statusCode = parseInt(data?.wallet_transactionable?.status_display?.code);
    let transactionType = String(data?.transaction_type);
    return (
        <Box>
            {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'linear-gradient(90deg, #FFC122 0.01%, #FFECBC 28.01%, #DBAA2C 100%)', height: 17, width: '100%', borderRadius: 49 }}>
                <div style={{ background: '#644312', height: 11, width: '98%', borderRadius: 49 }} />
            </div> */}
            <Box ref={containerRef} style={{ width: '100%', height: (type === 'transaction' ? '100%' : 500), overflow: 'scroll', background: 'transparent' }}>
                <Box style={{ padding: '30px', background: 'transparent'}}>
                    {/* Close button */}
                    <Box style={{ width: '100%', textAlign: 'right', position: 'relative', zIndex: 2 }}>
                        <Button onClick={handleClose}  style={{ minWidth: 26, aspectRatio: 1/1, padding: 5, borderRadius: 100,  }}>
                            <CloseIcon />
                        </Button>
                    </Box>

                    <Box style={{
                        marginBottom: 30,
                        // backgroundImage: 'url(/images/trade/mountain.png)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom center'
                    }}>
                        <Box className="flex-c-m">
                            {
                                type === 'transaction' && statusCode !== 60 && String(data?.transaction_type) === "withdraw"
                                    ? null
                                    : <FaCheck className='fs-50 txt-theme' />
                            }
                        </Box>
                        <Box className="flex-c-m">
                            <Box>
                                {
                                    type === 'transaction'
                                        ?
                                        <Typography
                                            className={
                                                statusCode === 60 || transactionType !== "withdraw"
                                                    ? 'text-gold-short'
                                                    : statusCode === 20 || statusCode === 40
                                                        ? styles.colorLightGrey
                                                        : styles.colorRed

                                            }
                                            style={{ fontWeight: 'bold' }}
                                            variant='h6'>
                                            {data?.transaction_code_display?.details[i18n.language] ? data?.transaction_code_display?.details[i18n.language] : data?.transaction_code_display?.details?.en}
                                        </Typography>
                                        :
                                        <Typography className='text-gold' style={{ fontWeight: 'bold' }} variant='h6'>
                                            {t('planHistory.planPurchase')}
                                        </Typography>
                                }
                            </Box>
                        </Box>
                        <Box className="flex-c-m">
                            <Box>
                                <p className='txt-subtitle'>{t('receipt.subtitle')}</p>
                            </Box>
                        </Box>
                    </Box>

                    {/* Receipt Details */}
                    <div>
                        {/* refNo */}
                        <Box className={styles.detailsLayout}>
                            <Typography variant="body2"  style={{ marginRight: 20 }}>{t('receipt.refNo')}</Typography>
                            <Typography variant="body2"  style={{ width: '70%', wordBreak: 'break-word' }}>{data?.ulid}</Typography>
                        </Box>

                        {/*Date Time */}
                        <Box className={styles.detailsLayout}>

                            <Typography variant="body2">
                                {t('receipt.dateTime')}
                            </Typography>

                            <Typography variant="body2" style={{ textAlign: "right" }}>
                                {data?.created_at}
                            </Typography>

                        </Box>

                        {/* refNo */}
                        {
                            data?.transaction_code_display?.code === "transfer" &&
                            <Box className={styles.detailsLayout}>
                                <Typography variant="body2" style={{ marginRight: 20 }}>{data?.details?.to ?  t('transfer.transferTo') : t('transfer.transferFrom')} </Typography>
                                <Typography variant="body2" style={{ width: '70%', wordBreak: 'break-word' , textAlign: 'right'}}>{data?.details?.to ? data?.details?.to : data?.details?.from}</Typography>
                            </Box>
                        }
                        

                        {/* Third Party Source and Order No */}
                        {
                            data?.details?.source &&
                            <Box className={styles.detailsLayout}>

                                <Typography variant="body2">
                                    {t('receipt.source')}
                                </Typography>

                                <Typography variant="body2" style={{ textAlign: "right" }}>
                                    {data?.merchant?.name[i18n.language] ? data?.merchant?.name[i18n.language]:data?.merchant?.name?.en }
                                </Typography>

                            </Box>
                        }

                        {
                            data?.details?.order_no &&
                            <Box className={styles.detailsLayout}>

                                <Typography variant="body2">
                                    {t('receipt.orderNo')}
                                </Typography>

                                <Typography variant="body2" style={{ textAlign: "right" }}>
                                    {data?.details?.order_no}
                                </Typography>

                            </Box>
                        }
                    </div>

                    <div className='divider-list-black'></div>

                    <div>
                        {
                            data?.wallet_transactionable?.details?.address &&
                            <Box className={styles.detailsLayout}>
                                <Typography variant="body2" style={{ marginRight: 20 }}>{t('receipt.address')}</Typography>
                                <Typography variant="body2" style={{ width: '70%', wordBreak: 'break-word' }}>{data?.wallet_transactionable?.details?.address}</Typography>
                            </Box>
                        }

                        {
                            data?.wallet_transactionable?.details?.txid &&
                            <Box className={styles.detailsLayout}>
                                <Typography variant="body2" style={{ marginRight: 20 }}>{t('receipt.txid')}</Typography>
                                <Typography variant="body2" style={{ width: '70%', wordBreak: 'break-word' }}>{data?.wallet_transactionable?.details?.txid}</Typography>
                            </Box>
                        }

                    </div>

                    {
                        (data?.wallet_transactionable?.details?.address || data?.wallet_transactionable?.details?.txid) &&
                        <div className='divider-list-black' />
                    }

                    {
                        transactionType === "withdraw"
                            ?
                            <>
                                <div>
                                    {/*status */}
                                    <Box className={styles.detailsLayout}>
                                        <Typography variant="body2">{t('receipt.status')}</Typography>
                                        <Typography variant="body2" style={{ textAlign: "right" }}>{data?.wallet_transactionable?.status_display?.details[i18n.language]}</Typography>
                                    </Box>
                                </div>
                                <div className='divider-list-black'></div>

                            </>
                            : null
                    }

                    {
                        type === 'transaction'
                            ? (
                                <>
                                    {/*transaction, type & amount*/}
                                    <>
                                        {/* <div>
                                            <Box>
                                                <Typography variant="body2" className={styles.colorGrey}>{t('receipt.transaction')}</Typography>
                                            </Box>

                                            <Box className={styles.detailsLayout}>
                                                <Typography variant="body2" className={styles.colorGrey}>
                                                    {data?.transaction_code_display?.details[i18n.language] ? data?.transaction_code_display?.details[i18n.language] : data?.transaction_code_display?.details?.en}
                                                </Typography>
                                                <Typography variant='body2' className={styles.colorGrey} style={{ textAlign: "right" }}>
                                                    {data?.factor === -1 && sign} $ {currencyFormat(formattedAmount)}
                                                </Typography>
                                            </Box>
                                        </div>

                                        <div className='divider-list-black'></div> */}
                                    </>

                                    {/* remark */}
                                    {
                                        _.size(data.remark) > 0 &&
                                        <>
                                            <Box style={{ marginBottom: 10 }}>
                                                <Typography variant="body2" style={{ marginBottom: 5 }}>{t('receipt.remark')} </Typography>
                                                {
                                                    data.remark?.member_remark
                                                        ? <Typography variant="body2">{data.remark?.member_remark ? data.remark?.member_remark : '-'}</Typography>
                                                        : <Typography variant="body2">{data.remark?.admin_remark ? data.remark?.admin_remark : '-'}</Typography>
                                                }
                                            </Box>

                                            <div className='divider-list-black'></div>
                                        </>
                                    }

                                    <>
                                        {/* total amount */}
                                        <Box className={styles.detailsLayout} style={{ margin: '10px 0 0 0' }}>
                                            <Typography variant="body1" className='text-gold-short' style={{ fontWeight: "bold" }}>{t('receipt.amount')}</Typography>
                                            <Typography variant="body1" className='text-gold-short' style={{ fontWeight: "bold", textAlign: "right", width: '70%' }}>
                                                {'$ ' + currencyFormat(formattedAmount)}
                                            </Typography>
                                        </Box>
                                    </>
                                </>
                            )
                            : (
                                <>
                                    {/*transaction, type & amount*/}
                                    <>
                                        <div>
                                            <Box>
                                                <Typography variant="body2">{t('receipt.transaction')}</Typography>
                                            </Box>

                                            <Box className={styles.detailsLayout} style={{ alignItems: 'flex-end' }}>
                                                <Typography variant="body2" style={{ width: '50%' }}>
                                                    {t('planHistory.planPurchase')} ({data?.plan_name?.[i18n.language]})
                                                </Typography>
                                                <Typography variant='body2' style={{ textAlign: "right" }}>
                                                    {data?.factor === -1 && sign} $ {currencyFormat(formattedAmount)}
                                                </Typography>
                                            </Box>
                                        </div>

                                        <div className='divider-list-black'></div>
                                    </>

                                    {/*profit limit, profit limit balance & roi */}
                                    <>
                                        <Box style={{ marginBottom: 10 }}>
                                            <Box className={styles.detailsLayout} style={{ margin: 0 }}>
                                                <Typography variant="body2">
                                                    {t('planHistory.profitLimit')}
                                                </Typography>

                                                <Typography variant='body2' style={{ textAlign: "right" }}>
                                                    {currencyFormat(parseFloat(data?.profit_limit).toFixed(2))}
                                                </Typography>
                                            </Box>

                                            <Box className={styles.detailsLayout} style={{ margin: 0 }}>
                                                <Typography variant="body2">
                                                    {t('planHistory.profitLimitBalance')}
                                                </Typography>

                                                <Typography variant='body2' style={{ textAlign: "right" }}>
                                                    {currencyFormat(parseFloat(data?.profit_limit_balance).toFixed(2))}
                                                </Typography>
                                            </Box>

                                            <Box className={styles.detailsLayout} style={{ margin: 0 }}>
                                                <Typography variant="body2">
                                                    {t('planHistory.mpProfitLimit')}
                                                </Typography>

                                                <Typography variant='body2' style={{ textAlign: "right" }}>
                                                    {currencyFormat(parseFloat(data?.trade_limit).toFixed(2))}
                                                </Typography>
                                            </Box>

                                            <Box className={styles.detailsLayout} style={{ margin: 0 }}>
                                                <Typography variant="body2">
                                                    {t('planHistory.mpProfitLimitBalance')}
                                                </Typography>

                                                <Typography variant='body2' style={{ textAlign: "right" }}>
                                                    {currencyFormat(parseFloat(data?.trade_limit_balance).toFixed(2))}
                                                </Typography>
                                            </Box>

                                            {
                                                (data?.roi?.total_distributions > 0) && (data?.roi?.percent > 0)
                                                    ?
                                                    <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-start' }}>
                                                        <Typography variant="body2">
                                                            {t('planHistory.roi')}
                                                        </Typography>

                                                        <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-end', flexDirection: 'column' }}>
                                                            <Typography variant='body2' style={{ textAlign: "right" }}>
                                                                {data?.roi?.received_times} / {data?.roi?.total_distributions} ({parseFloat(data?.roi?.percent).toFixed(2)} %)
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    :
                                                    null
                                            }

                                            {
                                                (parseFloat(data?.roi?.received_times) < parseFloat(data?.roi?.total_distributions) && data?.roi?.ROI_day >= currDate.format('YYYY-MM-DD')) &&
                                                <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-start' }}>
                                                    <Typography variant="body2">
                                                        {t('receipt.roiDate')}
                                                    </Typography>

                                                    <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-end', flexDirection: 'column' }}>
                                                        <Typography variant='body2' style={{ textAlign: "right" }}>
                                                            {data?.roi?.ROI_day}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            }
                                        </Box>

                                        <div className='divider-list-black'></div>
                                    </>

                                    {/* remark */}
                                    <>
                                        {
                                            data?.admin_remark
                                                ? (
                                                    <>
                                                        <Box style={{ marginBottom: 10 }}>
                                                            <Typography variant="body2" style={{ marginBottom: 5 }}>{t('receipt.remark')} </Typography>
                                                            {
                                                                data?.member_remark
                                                                    ? <Typography variant="body2">{data?.member_remark ? data?.member_remark : '-'}</Typography>
                                                                    : <Typography variant="body2">{data?.admin_remark ? data?.admin_remark : '-'}</Typography>
                                                            }
                                                        </Box>

                                                        <div className='divider-list-black'></div>
                                                    </>
                                                )
                                                : (null)
                                        }
                                    </>

                                    <>
                                        {/* total */}
                                        <Box className={styles.detailsLayout} style={{ margin: 0 }}>
                                            <Typography variant="body2" style={{ marginBottom: 5 }}>{t('receipt.total')} </Typography>
                                            <Typography variant="body2">$ {currencyFormat((parseFloat(data?.price)).toFixed(2))}</Typography>
                                        </Box>

                                        {/* service tax */}
                                        {/* <Box className={styles.detailsLayout} style={{ margin: 0 }}>
                                            <Typography variant="body2" style={{ marginBottom: 5 }}>{t('receipt.serviceFee')} </Typography>
                                            <Typography variant="body2">$ {currencyFormat(serviceFee.toFixed(2))}</Typography>
                                        </Box> */}

                                        {/* total amount */}
                                        <Box className={styles.detailsLayout} style={{ margin: '10px 0 0 0' }}>
                                            <Typography variant="body1" className='text-gold-short' style={{ fontWeight: "bold" }}>{t('receipt.amount')}</Typography>
                                            <Typography variant="body1" className='text-gold-short' style={{ fontWeight: "bold", textAlign: "right",width: '70%' }}>
                                                {/* {'$ ' + currencyFormat((parseFloat(data?.price) + serviceFee).toFixed(2))} */}
                                                {'$ ' + currencyFormat((parseFloat(data?.price)).toFixed(2))}
                                            </Typography>
                                        </Box>
                                    </>
                                </>
                            )
                    }

                    {/* payment method */}
                    {
                        _.size(data?.payment_methods) > 0 &&
                        <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-start' }}>
                            <Typography variant="body2" style={{ marginBottom: 5 }}>{t('receipt.paymentMethod')} </Typography>
                            <Box className={styles.detailsLayout} style={{ margin: 0, alignItems: 'flex-end', flexDirection: 'column' }}>
                                {
                                    _.size(data?.payment_methods) > 0
                                        ? _.map(data?.payment_methods, (payment, key) => (
                                            <Typography key={key} variant="body2">{payment}</Typography>
                                        ))
                                        : <Typography variant="body2">-</Typography>
                                }
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>

            {/* <div style={{ height: 43, position: 'relative', top: -10, background: 'linear-gradient(0deg, #CCC 3.64%, #F9F9F9 90.55%)', borderRadius: '0 0 30px 30px', filter: 'drop-shadow(0px -5px 9px rgba(0, 0, 0, 0.07))' }} /> */}
            {
                type !== 'transaction' && !isBottom &&
                <div style={{ position: 'absolute', bottom: 25, width: '100%', textAlign: 'center' }}>
                    <FaArrowCircleDown style={{ fontSize: 25, color: !isBottom ? theme.palette.primary.main : 'transparent' }} />
                </div>
            }

            {
                // completedLink
                //     ?
                //     <Box className="flex-c-m">
                //         <Button variant="contained" style={{ position: "absolute", bottom: 30 }} onClick={handleComplete}>{t('payment.completed')}</Button>
                //     </Box>
                //     : null
            }
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    colorGreen: {
        color: "#39A47D"
    },
    colorGrey: {
        color: "#565656"
    },
    colorLightGrey: {
        color: '#a4a4a4',
    },
    colorRed: {
        color: '#FF0101',
    },
    detailsLayout: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width:'100%',
        marginBottom: 15
    },
}));

export default Receipt;